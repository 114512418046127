import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, InfoIcon, WarningIcon, Tabs, List } from '../components';
import { getUserPropertiesCurl, getUserPropertiesJava, getUserPropertiesJavascript, getUserPropertiesNode, getUserPropertiesPerl, getUserPropertiesPython, getUserPropertiesPhp, getUserPropertiesRuby, getUserPropertiesGo, getUserPropertiesC, getUserPropertiesVB, getUserPropertiesGroovy, getUserPropertiesObjectiveC, getUserPropertiesSwift, storeUserPropertiesRaw, storeUserPropertiesCurl, storeUserPropertiesJava, storeUserPropertiesJavascript, storeUserPropertiesNode, storeUserPropertiesPerl, storeUserPropertiesPython, storeUserPropertiesPhp, storeUserPropertiesRuby, storeUserPropertiesGo, storeUserPropertiesC, storeUserPropertiesVB, storeUserPropertiesGroovy, storeUserPropertiesObjectiveC, storeUserPropertiesSwift, deleteUserPropertiesCurl, deleteUserPropertiesJava, deleteUserPropertiesJavascript, deleteUserPropertiesNode, deleteUserPropertiesPerl, deleteUserPropertiesPython, deleteUserPropertiesPhp, deleteUserPropertiesRuby, deleteUserPropertiesGo, deleteUserPropertiesC, deleteUserPropertiesVB, deleteUserPropertiesGroovy, deleteUserPropertiesObjectiveC, deleteUserPropertiesSwift } from 'requestExamples/user-accounts';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/user-property/{domain}/{key}',
  link: '#retrieve-properties'
}, {
  param: 'PUT',
  value: '/nodes/user-property/{domain}/{key}',
  link: '#store-properties'
}, {
  param: 'DELETE',
  value: '/nodes/user-property/{domain}/{key}',
  link: '#delete-properties'
}];
export const baseGetUrl = [{
  param: 'GET',
  value: '/nodes/user-property/{domain}/{key}'
}];
export const baseStoreUrl = [{
  param: 'PUT',
  value: '/nodes/user-property/{domain}/{key}'
}];
export const baseDeleteUrl = [{
  param: 'DELETE',
  value: '/nodes/user-property/{domain}/{key}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseGetUrl,
  baseStoreUrl,
  baseDeleteUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "managing-user-properties"
    }}>{`Managing user properties`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			You can use /nodes/user-property to get, store and delete properties for the logged in user for the current
			session
			<br />
			This information is set as an object with the properties: domains, keys, and values.
			<br />
			<br />
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <hr></hr>
    <br />
    <Expander title="Properties" mdxType="Expander">
	<Attributes items={[{
        name: 'domain',
        type: 'string (optional)',
        description: 'It is essentially a flat namespace and acts as a bucket to store one or more keys. Must be unique within the account.'
      }, {
        name: 'key" type="string (optional)',
        description: 'Used to identify its corresponding value. Each key can only have one corresponding value. Must be unique within the domain.'
      }, {
        name: 'value" type="string (optional)',
        description: 'The information to be stored. Identifiable by a unique key'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-properties"
    }}>{`Retrieve properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You can use a GET request to retrieve information related to a domain and key about the logged in user.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You can also retrieve all the keys from a domain not specifying the key parameter and using the "?children"
			query param after the domain.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			<strong>
				You won't be able to see the properties values if you don't specify the query param "?eprop=true".
			</strong>
		</p>
	</div>
	<Table title="Get properties" rows={baseGetUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'domain',
      type: 'string',
      description: 'The domain you want to retrieve the information from.'
    }, {
      name: 'key',
      type: 'string (optional)',
      description: 'Identify the value you want to get by its key.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getUserPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getUserPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getUserPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getUserPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getUserPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getUserPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getUserPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getUserPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getUserPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: getUserPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getUserPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getUserPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getUserPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getUserPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`By default, it returns the user-property object data. As stated above, you'll need to specify the query param "?eprop=true" if you want the value of the key to be present on the response.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.key",
        "createdOn": "2020-06-22T12:34:13-05:00",
        "modifiedOn": "2020-06-22T12:34:13-05:00",
        "uri": "/nodes/user-property/test.domain/test.key",
        "your_permissions": 64
    }
}

// Querying with the eprop param will result in an output like:

{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.key",
        "createdOn": "2020-06-22T12:47:20-05:00",
        "modifiedOn": "2020-06-22T12:47:20-05:00",
        "uri": "/nodes/user-property/test.domain/test.key",
        "your_permissions": 64,
        "propertyExProperties": {
            "domain": "test.domain",
            "key": "test.key",
            "value": "Example value for test.key on test.domain"
        }
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Not specifying the key and setting the children query param, would return all the keys stored under a certain domain.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example of a call without specifying the key param /user-property/test.domain?children=3
{
    "error": {
        "success": true
    },
    "message": {
        "name": "test.domain",
        "createdOn": "2020-06-22T12:50:01-05:00",
        "modifiedOn": "2020-06-22T12:50:01-05:00",
        "uri": "/nodes/user-property/test.domain",
        "your_permissions": 64,
        "total_children": 1,
        "children": [
            {
                "name": "test.key",
                "createdOn": "2020-06-22T12:50:01-05:00",
                "modifiedOn": "2020-06-22T12:50:01-05:00",
                "uri": "/nodes/user-property/test.domain/test.key",
                "your_permissions": 64,
                "total_children": 0
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "store-properties"
    }}>{`Store properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You can store properties for a logged in user by placing a PUT request to /nodes/user-property/domain/key
			and specifying the value for the key on the request body..
			<br />
			<br />
		</p>
	</div>
	<Table title="Store properties" rows={baseStoreUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'domain',
      type: 'string',
      description: 'The domain you want to set the information to.'
    }, {
      name: 'key',
      type: 'string',
      description: 'The key to identify the information within the domain.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'value',
      type: 'string',
      description: "The value for the key property you're creating."
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "value": "Example text to be stored on the key."
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: storeUserPropertiesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: storeUserPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: storeUserPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: storeUserPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: storeUserPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: storeUserPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: storeUserPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: storeUserPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: storeUserPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: storeUserPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: storeUserPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: storeUserPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: storeUserPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: storeUserPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: storeUserPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default node object.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "example-key",
        "createdOn": "2020-06-19T11:40:25-05:00",
        "modifiedOn": "2020-06-19T11:40:25-05:00",
        "uri": "/nodes/user-property/example.domain.com/example-key",
        "your_permissions": 64
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if either the domain from path or value from body parameters are not present in the request.`}</p>
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "delete-properties"
    }}>{`Delete properties`}</h2>
    <hr></hr>
    <Row mdxType="Row">
	<div>
		<p>
			You are also able to delete some information saved in a domain using a delete request.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You cannot delete a domain, only the keys in the domains.
			<br />
			<br />
		</p>
	</div>
	<Table title="Delete properties" rows={baseDeleteUrl} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'domain',
      type: 'string',
      description: 'The domain you want to retrieve the information from.'
    }, {
      name: 'key',
      type: 'string',
      description: 'Identify the value you want to get by its key.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: deleteUserPropertiesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: deleteUserPropertiesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: deleteUserPropertiesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: deleteUserPropertiesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: deleteUserPropertiesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: deleteUserPropertiesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: deleteUserPropertiesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: deleteUserPropertiesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: deleteUserPropertiesGo
    }, {
      tabTitle: 'C#',
      tabInfo: deleteUserPropertiesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: deleteUserPropertiesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: deleteUserPropertiesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: deleteUserPropertiesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: deleteUserPropertiesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default object if deletion goes well.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {}
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the key doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "44242db2-5278-473c-883f-bb4cdb23f14c",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      